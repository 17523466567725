
import { createRouter, createWebHistory } from 'vue-router'
import { authGuard} from '@auth0/auth0-vue';
import NissanProductPageInfo from '@/components/Nissan/NissanProductPageInfo'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/components/SplashPage.vue')
    },
    {
        path: '/Dashboard',
        name: 'Dashboard',
        component: () => import('@/components/UserDashboard.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/Nissan',
        name: 'Nissan',
        component: () => import('@/components/Nissan/NissanHomePage.vue')
    },

    {
        path:'/Nissan/Tutorials/:tutorial?',
        name: 'Advanced Tutorial',
        component: () => import('@/components/Nissan/AdvancedTutorial.vue')
    },
    {
        path: '/Nissan/CVTCreator',
        name: 'CVTCreator',
        component: () => import('@/components/Nissan/CVTFileCreator.vue'),
        meta: { permission: ["create:cvt", "create:cvt ELITEK"] },
        beforeEnter: authGuard
    },
    {
        path: '/Nissan/CVTDownload',
        name: 'CVTDownload',
        component: () => import('@/components/Nissan/CVTFileDownloader.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/Nissan/Purchase',
        name: 'NissanPurchase',
        component: () => import('@/components/PurchaseProductPage.vue'),
        props: NissanProductPageInfo
    },
    {
        path: '/Users',
        name: 'Users',
        component: () => import('@/components/Admin/Users/UsersTable.vue'),
        meta: { permission: "read:users" },
        beforeEnter: authGuard
    },
    {
        path: '/Messages',
        name: 'Messages',
        component: () => import('@/components/Admin/Messages/MessagesTable.vue'),
        meta: { permission: "read:messages" },
        beforeEnter: authGuard
    },
    {
        path: '/Test',
        name: 'Test',
        component: () => import('@/components/TestPage.vue'),
        meta: { permission: "read:test" },
        beforeEnter: authGuard
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/',
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { el: to.hash };
        }

        // If the route has a saved position, use it
        if (savedPosition) {
            return { ...savedPosition, behavior: 'auto' };
        }

        // Otherwise, scroll to the top
        return { top: 0, behavior: 'auto' };
    },
})



export default router;
